.subtitles {
  padding: var(--subs-page-padding);
  background-color: var(--subs-theme-color-bg-primary);
  color: var(--subs-theme-color-fg-primary);
  font-size: var(--subs-font-size);
  height: 100%;
}

.lines {
  line-height: 1.5;
  overflow-y: scroll;
  scrollbar-width: none;
  height: 100%;
}

.lastPhrase {
  color: gray;
}

@keyframes dotty {
  0%   { content: ''; }
  25%  { content: '.'; }
  50%  { content: '..'; }
  75%  { content: '...'; }
  100% { content: ''; }
}

.waiting::after {
  display: inline-block;
  animation: dotty steps(1, end) 1s infinite;
  content: '';
}
