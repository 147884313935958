.header {
  background-color: var(--bg-secondary);
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: var(--bg-secondary);
}

.brand {
  display: flex;
  gap: 0.5rem;
}

.appName {
  font-size: 1.3rem;
  font-family: 'Dosis', sans-serif;
  font-weight: 500;
}

.settings {
  font-size: 1.3rem;
}
