.subtitlesHeader {
  padding-left: var(--subs-page-padding);
  padding-right: var(--subs-page-padding);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: var(--header-footer-height);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--subs-theme-color-bg-secondary);
  color: var(--subs-theme-color-fg-secondary);
}

.appName {
  flex: 1;
  font-size: 1.1rem;
  font-family: 'Dosis', sans-serif;
  font-weight: bold;
}
